import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@/js/icons';

import app from '@/app';
import router from '@/router';
import store from '@/store';
import SupWizPrimeVue from 'supwiz/primevue/plugin';

import '@/assets/tailwind.css';

import SpinnerFullscreen from 'supwiz/components-vue3/general/SpinnerFullscreen.vue';
import DebounceButton from 'supwiz/components-vue3/DebounceButton.vue';

const isProduction = process.env.NODE_ENV === 'production';

// PrimeVue setup
app.use(SupWizPrimeVue);

// Custom components
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('SpinnerFullscreen', SpinnerFullscreen);
app.component('DebounceButton', DebounceButton);

app.use(router);
app.use(store);

app.mount('#app');

if (!isProduction) {
  const consoleErrorOrg = console.error;
  // eslint-disable-next-line no-inner-declarations
  function consoleErrorMock() {
    // eslint-disable-next-line prefer-rest-params
    consoleErrorOrg(...arguments);
    if (app) {
      app.config.globalProperties.$toast.add({
        summary: '[SupwizDEV]: An error occurred in console',
        detail: 'Console has errors',
        severity: 'error',
        life: 2000,
      });
    }
  }
  console.error = consoleErrorMock;
}
