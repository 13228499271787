import endpoints from '@/js/endpoints';
import { getModuleProps } from '@/js/utils';
import GeneralTemplate from '@/js/vuexTemplates/general';
import axios from 'axios';

const callState = {
  savedFilters: null,
  summarizing: false,
  isSendingIds: false,
  exporting: false,
  isFetching: false,
};

const callGetters = {
  getSavedFilters: (state) => state.savedFilters,
  isExporting: (state) => state.exporting,
};

const mutations = {
  setSavedFilters(state, filters) {
    state.savedFilters = filters;
  },
  setIsSummarizing(state, value) {
    state.summarizing = value;
  },
  setIsSendingIds(state, value) {
    state.isSendingIds = value;
  },
  setIsExporting(state, value) {
    state.exporting = value;
  },
  setIsFetching(state, value) {
    state.isFetching = value;
  },
};

const templates = [
  new GeneralTemplate(endpoints.call, 'call'),
];

const actions = {
  async fetchCalls({ rootGetters, commit, dispatch }, { params, queryParams }) {
    try {
      commit('setIsFetching', true);
      const request = { ...rootGetters['auth/headerAuthorization'] };
      const queryString = new URLSearchParams(queryParams).toString();
      const { data } = await axios.post(`${endpoints.call}filtered_list/?${queryString}`, params, request);
      const { results, count } = data;
      commit('updatePagination', { count });
      commit('setItems', results);
      commit('setIsFetching', false);
      return results;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch calls',
        text: error.message,
      }, { root: true });
      throw error;
    } finally {
      commit('setIsFetching', false);
    }
  },
  async fetchSummary({
    dispatch, commit, rootGetters,
  }, callId) {
    commit('setIsSummarizing', true);
    const auth = rootGetters['auth/headerAuthorization'];
    try {
      const resp = await axios.post(`${endpoints.call}/${callId}/add_summary/`, {}, auth);
      commit('task/addTask', {
        celeryId: resp.data.celery_id,
        callbackDone: () => {
          commit('setIsSummarizing', false);
          dispatch('fetchItemDetails', { id: callId, transcript: true });
        },
        callbackFailed: (error) => {
          dispatch('sidebar/showWarning', {
            title: 'Failed to create summary',
            text: error,
            severity: 'error',
          }, { root: true });
          commit('setIsSummarizing', false);
        },
      }, { root: true });
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to create summary',
        text: error.message,
      }, { root: true });
      commit('setIsSummarizing', false);
    }
  },
  async rehandleCalls({ rootGetters, commit, dispatch }, callIds) {
    commit('setIsSendingIds', true);
    try {
      const request = {
        params: {
          ids: `${callIds.join(',')}`,
        },
        ...rootGetters['auth/headerAuthorization'],
      };
      await axios.post(`${endpoints.call}/rehandle_calls/`, {}, request);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to rehandle calls',
        text: error.message,
      }, { root: true });
    } finally {
      commit('setIsSendingIds', false);
    }
  },
  async rehandleAllCalls({ rootGetters, commit, dispatch }, params) {
    commit('setIsSendingIds', true);
    try {
      const request = {
        ...rootGetters['auth/headerAuthorization'],
      };
      await axios.post(`${endpoints.call}/rehandle_calls/`, params, request);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to rehandle calls',
        text: error.message,
      }, { root: true });
    } finally {
      commit('setIsSendingIds', false);
    }
  },
  async exportCalls({
    rootGetters, commit, dispatch,
  }, { fields, params } = { fields: [], params: {} }) {
    commit('setIsExporting', true);
    try {
      const request = { ...rootGetters['auth/headerAuthorization'] };
      const queryString = new URLSearchParams({ fields: fields.join(',') }).toString();
      const { data } = await axios.post(`${endpoints.call}export_to_excel/?${queryString}`, params, request);
      return data;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to export calls',
        text: error.message,
      }, { root: true });
    } finally {
      commit('setIsExporting', false);
    }
    return null; // Make lint happy
  },
};
export default {
  namespaced: true,
  ...getModuleProps({
    state: callState, getters: callGetters, mutations, actions,
  }, templates),
};
