import axios from 'axios';
import endpoints from '@/js/endpoints';

const taskState = {
  runningTasks: {},
};

const taskGetters = {};

const taskMutations = {
  addTask(state, {
    celeryId, callbackDone, callbackFailed, callbackUpdate,
  }) {
    state.runningTasks[celeryId] = {
      celeryId, callbackDone, callbackFailed, callbackUpdate,
    };
  },
  removeTask(state, celeryId) {
    delete state.runningTasks[celeryId];
  },
  clearTasks(state) {
    state.runningTasks = {};
  },
};

const taskActions = {
  async fetchTasks({ rootGetters }, celeryIds) {
    const request = {
      params: { celery_id__in: celeryIds.join(',') },
      ...rootGetters['auth/headerAuthorization'],
    };
    const { data } = await axios.get(endpoints.task, request);
    return data.results;
  },
  async abortTask({ rootGetters }, celeryId) {
    const endpoint = `${endpoints.task}${celeryId}/abort/`;
    const resp = await axios.post(endpoint, {}, rootGetters['auth/headerAuthorization']);
    return resp.data.success;
  },
};

export default {
  namespaced: true,
  state: taskState,
  getters: taskGetters,
  mutations: taskMutations,
  actions: taskActions,
};
