import axios from 'axios';
import { objToCamel, objToSnake } from 'supwiz/util/data';

const configEndpoint = '/api/anonymization/config/';
const settingsEndpoint = '/api/anonymization/custom-settings/';
const anonymizationState = {
  config: null,
  ners: {},
  customOptions: {},
  isFetchingConfig: false,
  isFetchingSettings: false,
};
const getters = {

};
const mutations = {
  setConfig(state, payload) {
    state.config = objToCamel(payload);
  },
  setNers(state, payload) {
    state.ners = objToCamel(payload);
  },
  setCustomOptions(state, payload) {
    state.customOptions = objToCamel(payload);
  },
  setIsFetchingConfig(state, value) {
    state.isFetchingConfig = value;
  },
  setIsFetchingSettings(state, value) {
    state.isFetchingSettings = value;
  },
};
const actions = {
  async fetchAnonymizationConfig({ rootState, commit, dispatch }, id) {
    commit('setIsFetchingConfig', true);
    try {
      const resp = await axios.get(`${configEndpoint}${id}/`, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setConfig', resp.data);
      commit('setIsFetchingConfig', false);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch anonymization',
        text: error.message,
        severity: 'error',
      }, { root: true });
      commit('setIsFetchingConfig', false);
    }
  },
  async fetchAnonymizationSettings({ rootState, commit, dispatch }) {
    commit('setIsFetchingSettings', true);
    try {
      const resp = await axios.get(`${settingsEndpoint}/`, {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      });
      commit('setNers', resp.data.ners);
      commit('setCustomOptions', resp.data.custom_options);
      commit('setIsFetchingSettings', false);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch entities',
        text: error.message,
        severity: 'error',
      }, { root: true });
      commit('setIsFetchingSettings', false);
    }
  },
  async updateAnonymization({ rootState, commit, dispatch }, { data, id }) {
    try {
      const resp = await axios.put(`${configEndpoint}${id}/`,
        objToSnake(data),
        {
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      if (resp.status === 200) {
        dispatch('sidebar/showWarning', {
          text: 'Anonymization updated successfully.',
          title: 'Operation successful',
          severity: 'info',
        }, { root: true });
        commit('setConfig', resp.data);
        commit('setIsFetchingConfig', false);
      } else {
        dispatch('sidebar/showWarning', {
          title: 'Failed to update anonymization',
          text: 'Something went wrong',
          severity: 'error',
        }, { root: true });
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to update anonymization',
        text: error.message,
        severity: 'error',
      }, { root: true });
    }
  },
};
export default {
  namespaced: true,
  state: anonymizationState,
  getters,
  mutations,
  actions,
};
