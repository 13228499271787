import { library } from '@fortawesome/fontawesome-svg-core';
// Import the icons we use
// We do this to decrease the bundle-size
import {
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faBell,
  faBuilding,
  faList,
  faExternalLinkAlt,
  faBars,
  faSpinner,
  faCogs,
  faEye,
  faEyeSlash,
  faExclamation,
  faSlidersH,
  faInfoCircle,
  faChartPie,
  faChartLine,
  faPlus,
  faTrashAlt,
  faArrowsAlt,
  faTable,
  faEllipsisVertical,
  faInfo,
  faHeadset,
  faUpLong,
  faSmile,
  faFrown,
  faCircle,
  faMeh,
  faChartColumn,
  faTimes,
  faMicrophoneAlt,
  faClock,
  faLock,
  faFilter,
  faBookmark,
  faMinus,
  faMinusCircle,
  faEdit,
  faChartBar,
  faLink,
  faCog,
  faAsterisk,
  faCheck,
  faComment,
  faSortAmountUp,
  faArrowRight,
  faCheckCircle,
  faWrench,
  faDownLong,
  faMicroscope,
  faTimesCircle,
  faQuestionCircle,
  faSearch,
  faUserTie,
  faUserLock,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faInfo,
  faDownLong,
  faUpLong,
  faChartColumn,
  faCircle,
  faAngleUp,
  faAngleRight,
  faWrench,
  faSortAmountUp,
  faHeadset,
  faLock,
  faArrowsAlt,
  faAngleDown,
  faEllipsisVertical,
  faSlidersH,
  faSmile,
  faMicroscope,
  faSpinner,
  faComment,
  faFrown,
  faMeh,
  faBookmark,
  faEdit,
  faExclamation,
  faTable,
  faPlus,
  faChartBar,
  faChartLine,
  faMinus,
  faMinusCircle,
  faCog,
  faAsterisk,
  faArrowRight,
  faExternalLinkAlt,
  faEye,
  faChartPie,
  faInfoCircle,
  faEyeSlash,
  faFilter,
  faBell,
  faBuilding,
  faTrashAlt,
  faClock,
  faTimes,
  faLink,
  faBars,
  faList,
  faMicrophoneAlt,
  faCogs,
  faCheck,
  faQuestionCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faUserTie,
  faUserLock,
  faDownload,
);

export default library;
