const analyzerPages = [
  {
    label: 'Configuration',
    to: { name: 'configuration' },
    icon: 'cog',
  },
  {
    label: 'Calls',
    to: { name: 'call' },
    icon: 'list',
    matchOn: ['call-overview', 'call-single'],
  },
  {
    label: 'Agent Insights',
    to: { name: 'agent-insights' },
    icon: 'headset',
  },
  {
    label: 'Conversation Insights',
    to: { name: 'conversation-insights' },
    icon: 'comment',
  },
  {
    label: 'Analytics',
    to: { name: 'analytics' },
    icon: 'microscope',
  },
  {
    label: 'Status',
    to: { name: 'status' },
    icon: 'info-circle',
  },
];

const sidebarState = {
  showSidebar: false,
  pageLoading: false,
};

const sidebarGetters = {
  pages: () => analyzerPages,
  loggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
};

const mutations = {
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setLoading(state, payload) {
    state.pageLoading = payload;
  },
};

const actions = {
  showWarning({ dispatch }, { title, text, severity = 'error' }) {
    dispatch('templateStore/templateSendNotification', {
      title,
      text,
      severity,
      toast: true,
    }, { root: true });
  },
};

export default {
  namespaced: true,
  state: sidebarState,
  getters: sidebarGetters,
  mutations,
  actions,
};
