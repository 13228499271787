import { createStore } from 'vuex';
import templateStore from 'supwiz/components-vue3/template/templateStore';
import anonymizationStore from 'supwiz/components-vue3/anonymization/anonymizationStore';
import statisticsFiltersStore from 'supwiz/components-vue3/statisticsFilters/statisticsFiltersStore';
import sidebar from './modules/sidebar';
import auth from './modules/auth';
import analyzer from './modules/analyzer';
import statistics from './modules/statistics';
import connector from './modules/connector';
import call from './modules/call';
import category from './modules/category';
import externalSystem from './modules/externalSystem';
import task from './modules/task';
import statsConfiguration from './modules/statsConfiguration';
import administration from './modules/administration';

const incrementPlugin = (store) => {
  // check if token should be refreshed every five minutes
  setInterval(() => {
    store.dispatch('auth/inspectToken', { shouldRefresh: true });
  }, 5 * 60 * 1000);
  // fetch task status
  setInterval(async () => {
    const runningTasks = store.state.task.runningTasks;
    const celeryIds = Object.keys(runningTasks);
    if (celeryIds.length) {
      const tasks = await store.dispatch('task/fetchTasks', celeryIds);
      tasks.forEach((t) => {
        if (t.celery_id in runningTasks) {
          if (t.status === 'pending') {
            const callback = runningTasks[t.celery_id].callbackUpdate;
            if (callback) {
              callback(t);
            }
          } else if (t.status === 'done') {
            const callback = runningTasks[t.celery_id].callbackDone;
            if (callback) {
              callback(t);
            }
            store.commit('task/removeTask', t.celery_id);
          } else if (t.status === 'failed') {
            const callback = runningTasks[t.celery_id].callbackFailed;
            if (callback) {
              callback(t.error_message ? t.error_message : null);
            }
            store.commit('task/removeTask', t.celery_id);
          }
        }
      });
    }
  }, 1000);
};
// eslint-disable-next-line new-cap
export default new createStore({
  state: {
    isFetching: false,
  },
  mutations: { },
  actions: {
    async authentication({
      state,
      commit,
      dispatch,
      rootState,
    }) {
      if (state.auth.jwt) {
        try {
          // If Promise does not reject, it is assumed that token was verified
          await dispatch('auth/verifyToken');
          await dispatch('auth/getPermissions');
          commit('auth/updateLoggedIn', true);
          commit('auth/updateOngoingSession', true);
          if (rootState.auth.user === null) {
            await dispatch('auth/getUser');
          }
          dispatch('connector/fetchItems');
          return 'success';
        } catch (e) {
          commit('auth/updateLoggedIn', false);
          commit('auth/updateOngoingSession', false);
          throw new Error(e);
        }
      }
      return 'no token';
    },
  },
  modules: {
    auth,
    task,
    externalSystem,
    connector,
    call,
    templateStore,
    sidebar,
    analyzer,
    statistics,
    anonymizationStore,
    category,
    statisticsFiltersStore,
    statsConfiguration,
    administration,
  },
  plugins: [incrementPlugin],
});
