<template>
  <PvButton
    v-tooltip="tooltip"
    :class="{
      'w-full': block,
      'motion-safe:animate-debounce-pulse': animate,
    }"
    :label="text"
    :size="size"
    :severity="severity"
    :disabled="debouncing || disabled"
    @click="buttonClicked"
  />
</template>
<script>
export default {
  name: 'DebounceButton',
  emits:['click'],
  props: {
    text: {
      type: String,
      required: true,
    },
    debounceTime: {
      type: Number,
      default: 5000,
    },
    severity: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      debouncing: false,
      animate: false,
    };
  },
  methods: {
    buttonClicked() {
      this.$emit('click');
      this.debouncing = true;
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
        this.debouncing = false;
      }, this.debounceTime);
    },
  },
};
</script>
